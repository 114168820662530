import { Injectable, inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';

@Injectable({
    providedIn: 'root',
})
class LogInPermission {
    constructor(
        private _serviceStorage: StorageService,
        private _route: Router,
    ) {}
    canActivate(): boolean {
        const isLogIn = this._serviceStorage.isLoggedIn();
        if (!isLogIn) {
            return true;
        }
        this._route.navigate(['/dashboard']);
        return true;
    }
}

export const checkLogInGuard: CanActivateFn = (route, state) => {
    return inject(LogInPermission).canActivate();
};
