import { Mapper } from 'src/app/utilitis/base/mapper';
import { ClientPublicEntitie } from '../entities/client.entitie';
import { ClientPublicModel } from 'src/app/domain/models/client.model';


export class ClientPublicMapper extends Mapper<
    ClientPublicEntitie | ClientPublicEntitie[],
    ClientPublicModel | ClientPublicModel[]
> {
    mapperArrayFrom(payload: ClientPublicEntitie[]): ClientPublicModel[] {
        let data: ClientPublicModel[] = [];
        payload.forEach((value: ClientPublicEntitie) => {
            const dataConvert = this.mapperFrom(value);
            data.push(dataConvert);
        });
        return data;
    }

    mapperTo(payload: ClientPublicModel): ClientPublicEntitie {
        return {
            _uuid: payload._uuid,
            full_name: payload.fullName,
            email: payload.email,
            phone_number: payload.phoneNumber
        };
    }

    mapperFrom(payload: ClientPublicEntitie): ClientPublicModel {
        return {
            _uuid: payload._uuid,
            fullName: payload.full_name,
            email: payload.email,
            phoneNumber: payload.phone_number
        };
    }
}
