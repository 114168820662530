import { Component } from '@angular/core';
import { LayoutService } from '../../services/app.layout.service';

@Component({
    selector: 'app-footer',
    templateUrl: './app.footer.component.html',
    standalone: true,
})
export class AppFooterComponent {
    year: number = 0;
    constructor(public layoutService: LayoutService) {
        const now = new Date();
        this.year = now.getFullYear();
    }
}
