import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IConfig } from '../utilitis/interfaces/config';
import { Observable } from 'rxjs';
import { ResponseMessage } from '../utilitis/interfaces/response';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

    private _api = environment.back_api

    constructor(
        private _httpClient: HttpClient
    ) { }

    startConfig(payload: IConfig): Observable<ResponseMessage>{
        return this._httpClient.post<ResponseMessage>(`${this._api}/init-config`, payload)
    }
}
