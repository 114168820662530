import { Injectable } from '@angular/core';
import { PublicService } from 'src/app/services/public.service';
import { Observable, map } from 'rxjs';
import { PersonalShopperPublicMapper } from '../mappers/personal-shopper-public.mapper';
import { PersonalShopperPublicEntitie } from '../entities/personalShopper.entitie';
import { PersonalShopperPublicModel } from 'src/app/domain/models/personalShopper.model';
import { PersonalShopperPublicRepository } from 'src/app/domain/repositorys/personal-shopper-public.repository';


@Injectable({ providedIn: 'root' })
export class PersonalShopperPublicIRepository extends PersonalShopperPublicRepository {
    private _mapperPersonalShopperPublic= new PersonalShopperPublicMapper();

    constructor(private _servicePublic: PublicService) {
        super();
    }

    getAll(): Observable<PersonalShopperPublicModel[]> {
        return this._servicePublic
            .getAllShoppers()
            .pipe(
                map((e: PersonalShopperPublicEntitie[]) =>
                    this._mapperPersonalShopperPublic.mapperArrayFrom(e),
                ),
            );
    }
}
