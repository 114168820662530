import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ActionsP from '../actions';
import { CityModel } from 'src/app/domain/models/city.model';
import { catchError, exhaustMap, finalize, map, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { CityIRepository } from 'src/app/adapters/repositorys/city.IRepository';

@Injectable()
export class CityEffect {
    constructor(
        private actions$: Actions,
        private _cityService: CityIRepository,
    ) {}

    getAllCitys$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionsP.getAllCitysByDepartament),
            exhaustMap((actions) => {
                return this._cityService.getAllCitys(actions.id).pipe(
                    map((resp: CityModel[]) => {
                        return ActionsP.getAllCitysByDepartamentSuccess({
                            citys: resp,
                        });
                    }),
                    catchError(() => {
                        return of();
                    }),
                    finalize(() => {}),
                );
            }),
        ),
    );
}
