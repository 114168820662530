import { Observable } from 'rxjs';
import {
    ResponseData,
    ResponseMessage,
} from 'src/app/utilitis/interfaces/response';

export abstract class ProfileRepository {
    abstract updateToken(): Observable<ResponseData>;
    abstract logOut(): Observable<ResponseMessage>;
}
