import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ActionsP from '../actions';
import { catchError, exhaustMap, finalize, map, of } from 'rxjs';
import { CountryIRepository } from 'src/app/adapters/repositorys/country.IRepository';
import { CountryModel } from 'src/app/domain/models/country.model';

@Injectable()
export class CountryEffect {
    constructor(
        private actions$: Actions,
        private _countryRepository: CountryIRepository,
    ) {}

    getAllContry$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionsP.getAllCountry),
            exhaustMap((actions) => {
                return this._countryRepository.getAllContrys().pipe(
                    map((resp: CountryModel[]) => {
                        return ActionsP.getAllContrySuccess({ contrys: resp });
                    }),
                    catchError(() => {
                        return of();
                    }),
                    finalize(() => {}),
                );
            }),
        ),
    );
}
