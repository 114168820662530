<div class="layout-topbar">
    <a
        class="layout-topbar-logo justify-content-center"
        routerLink="/dashboard"
    >
        <img src="assets/images/LogoTOGGO.webp" alt="logo" />
    </a>

    <button
        #menubutton
        class="p-link layout-menu-button layout-topbar-button"
        (click)="layoutService.onMenuToggle()"
    >
        <i class="pi pi-bars"></i>
    </button>

    <button
        #topbarmenubutton
        class="p-link layout-topbar-menu-button layout-topbar-button"
        (click)="layoutService.showProfileSidebar()"
    >
        <i class="pi pi-ellipsis-v"></i>
    </button>

    <div
        #topbarmenu
        class="layout-topbar-menu"
        [ngClass]="{
            'layout-topbar-menu-mobile-active':
                layoutService.state.profileSidebarVisible
        }"
    >
        <button class="p-link layout-topbar-button" pTooltip="Perfil">
            <i class="pi pi-user"></i>
            <span>Profile</span>
        </button>
        <button
            class="p-link layout-topbar-button"
            pTooltip="Cerrar sesion"
            (click)="logOut()"
        >
            <i class="pi pi-sign-out"></i>
            <span>Sign Out</span>
        </button>
    </div>
</div>
