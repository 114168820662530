import { ProductGlobalEntitie } from './../entities/product-global.entitie';
import { Mapper } from 'src/app/utilitis/base/mapper';
import { ProductGlobalModel } from 'src/app/domain/models/product-global.model';


export class ProductGlobalMapper extends Mapper<
    ProductGlobalEntitie | ProductGlobalEntitie[],
    ProductGlobalModel | ProductGlobalModel[]
> {
    mapperArrayFrom(payload: ProductGlobalEntitie[]): ProductGlobalModel[] {
        let data: ProductGlobalModel[] = [];
        payload.forEach((value: ProductGlobalEntitie) => {
            const dataConvert = this.mapperFrom(value);
            data.push(dataConvert);
        });
        return data;
    }

    mapperTo(payload: ProductGlobalModel): ProductGlobalEntitie {
        return {
            _uuid: payload._uuid,
            title: payload.title,
            sku: payload.sku,
            color: payload.color,
            size: payload.size,
            stock: payload.stock,
            price: payload.price,
            offer_quantitys: payload.offerQuantitys
        };
    }

    mapperFrom(payload: ProductGlobalEntitie): ProductGlobalModel {
        return {
            _uuid: payload._uuid,
            title: payload.title,
            sku: payload.sku,
            color: payload.color,
            size: payload.size,
            stock: payload.stock,
            price: payload.price,
            offerQuantitys: payload.offer_quantitys
        };
    }
}
