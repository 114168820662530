import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { CityModel } from 'src/app/domain/models/city.model';
import { CityRepository } from 'src/app/domain/repositorys/city.repository';
import { CityService } from 'src/app/services/city.service';
import { CityMapper } from '../mappers/city.mapper';

@Injectable({
    providedIn: 'root',
})
export class CityIRepository extends CityRepository {
    private _cityMapper = new CityMapper();

    constructor(private _cityService: CityService) {
        super();
    }

    getAllCitys(id: number): Observable<CityModel[]> {
        return this._cityService
            .getAllCityByDepartament(id)
            .pipe(map(this._cityMapper.mapperArrayFrom));
    }
}
