import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { LayoutService } from '../../services/app.layout.service';
import { AppMenuitemComponent } from '../menuItem/app.menuitem.component';
import { CommonModule } from '@angular/common';
import { Rol } from 'src/app/utilitis/enums/rol';

import { faBookmark, faBoxesStacked, faCalendarCheck, faCreditCard, faFileSignature, faGavel, faHome, faIdBadge, faIdCardClip, faLayerGroup, faListCheck, faMoneyCheck, faPercent, faReceipt, faRetweet, faShop, faStore, faTag, faTransgender, faTruck, faTruckFast, faUserSecret, faUserTie, faWarehouse } from '@fortawesome/free-solid-svg-icons';


@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
    standalone: true,
    imports: [AppMenuitemComponent, CommonModule],
})
export class AppMenuComponent implements OnInit {
    model: any[] = [];
    rol= Rol;

    constructor(public layoutService: LayoutService) {}

    ngOnInit() {
        this.model = [
            {
                label: 'Home',
                allow: [this.rol.ADMIN,this.rol.VENDEDOR],
                items: [
                    {
                        label: 'Dashboard',
                        icon: faHome,
                        routerLink: ['/dashboard'],
                        allow: [this.rol.ADMIN,this.rol.VENDEDOR],
                    },
                ],
            },
            {
                label: 'Usuarios',
                allow: [this.rol.ADMIN, this.rol.VENDEDOR],
                items: [
                    {
                        base:'client',
                        label: 'Clientes',
                        icon: faIdBadge,
                        routerLink: ['/user/client/list'],
                        allow: [this.rol.ADMIN],
                    },
                    {
                        base:'personal-shopper',
                        label: 'Personal Shopper',
                        icon: faUserTie,
                        routerLink: ['/user/personal-shopper/list'],
                        allow: [this.rol.ADMIN, this.rol.VENDEDOR],
                    },
                    {
                        base:'seller',
                        label: 'Fabricantes',
                        icon: faIdCardClip,
                        routerLink: ['/user/seller/list'],
                        allow: [this.rol.ADMIN],
                    },
                ],
            },
            {
                label: 'Inventario',
                allow: [this.rol.ADMIN,this.rol.VENDEDOR, this.rol.SHOPPER],
                items: [
                    {
                        base:'product',
                        label: 'Productos',
                        icon: faBoxesStacked,
                        routerLink: ['/inventory/product/list'],
                        badge: 'NEW',
                        allow: [this.rol.ADMIN,this.rol.VENDEDOR],
                    },
                    {
                        base:'return',
                        label: 'Devoluciones',
                        icon: faRetweet,
                        routerLink: ['/inventory/return/list'],
                        badge: 'NEW',
                        allow: [this.rol.ADMIN,this.rol.VENDEDOR,this.rol.SHOPPER],
                    },
                    {
                        base:'purchase',
                        label: 'Orden de compra',
                        icon: faFileSignature,
                        routerLink: ['/inventory/purchase/list'],
                        badge: 'NEW',
                        allow: [this.rol.ADMIN,this.rol.SHOPPER],
                    },
                    {
                        base:'sale',
                        label: 'Ventas',
                        icon: faTag,
                        routerLink: ['/inventory/sale/list'],
                        badge: 'NEW',
                        allow: [this.rol.ADMIN,this.rol.VENDEDOR,this.rol.SHOPPER],
                    },
                ],
            },
            {
                label: 'Configuración',
                allow: [this.rol.ADMIN],
                items: [
                    {
                        base:'category',
                        label: 'Categorias',
                        icon: faLayerGroup,
                        routerLink: ['/config/category/list'],
                        allow: [this.rol.ADMIN],
                    },
                    {
                        base:'brand',
                        label: 'Marcas',
                        icon: faBookmark,
                        routerLink: ['/config/brand/list'],
                        allow: [this.rol.ADMIN],
                    },

                    {
                        base:'offer',
                        label: 'Ofertas',
                        icon: faPercent,
                        routerLink: ['/config/offer/list'],
                        allow: [this.rol.ADMIN],
                    },
                    {
                        base:'mall',
                        label: 'Centro comercial',
                        icon: faStore,
                        routerLink: ['/config/mall/list'],
                        allow: [this.rol.ADMIN],
                    },
                    {
                        base:'task',
                        label: 'Tareas Scrapping',
                        icon: faListCheck,
                        routerLink: ['/config/task/list'],
                        allow: [this.rol.ADMIN],
                    },
                    {
                        base:'rol',
                        label: 'Rol',
                        icon: faUserSecret,
                        routerLink: ['/config/rol/list'],
                        allow: [],
                    },
                    {
                        base:'gender',
                        label: 'Genero',
                        icon: faTransgender,
                        routerLink: ['/config/gender/list'],
                        allow: [],
                    },
                    {
                        base:'type_account',
                        label: 'Tipo de cuenta',
                        icon: faMoneyCheck,
                        routerLink: ['/config/type_account/list'],
                        allow: [],
                    },
                    {
                        base:'type_card',
                        label: 'Tipo de tarjeta de crédito',
                        icon: faCreditCard,
                        routerLink: ['/config/type_card/list'],
                        allow: [],
                    },
                ],
            },
            {
                label: 'Logistica',
                allow: [this.rol.ADMIN, this.rol.VENDEDOR,this.rol.SHOPPER],
                items: [
                    {
                        base:'warhouse',
                        label: 'Warehouse',
                        icon: faWarehouse,
                        routerLink: ['/logistic/warehouse/list'],
                        allow: [this.rol.ADMIN, this.rol.VENDEDOR,this.rol.SHOPPER],
                    },
                    {
                        base:'gavel',
                        label: 'Disputas',
                        icon: faGavel,
                        routerLink: ['/logistic/gavel/list'],
                        allow: [this.rol.ADMIN,this.rol.SHOPPER],
                    },
                    {
                        base:'meet',
                        label: 'Citas',
                        icon: faCalendarCheck,
                        routerLink: ['/logistic/meet/list'],
                        allow: [this.rol.ADMIN,this.rol.SHOPPER],
                    },
                    {
                        base:'carrie',
                        label: 'Envios',
                        icon: faTruck,
                        routerLink: ['/logistic/shipment/list'],
                        badge: 'NEW',
                        allow: [this.rol.ADMIN, this.rol.VENDEDOR,this.rol.SHOPPER],
                    },
                    {
                        base:'shipment-warehouse',
                        label: 'Envíos al warehouse',
                        icon: faTruckFast,
                        routerLink: ['/logistic/shipment-warehouse/list'],
                        badge: 'NEW',
                        allow: [this.rol.ADMIN, this.rol.VENDEDOR],
                    },
                ],
            },
            {
                label: 'Financiero',
                allow: [this.rol.ADMIN],
                items: [
                    {
                        base:'transaction',
                        label: 'Transacciones',
                        icon: faReceipt,
                        routerLink: ['/financie/transaction/list'],
                        allow: [this.rol.ADMIN],
                    },
                ],
            },
        ];
    }
}
