import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CountryEntitie } from '../adapters/entities/country.entitie';

@Injectable({
    providedIn: 'root',
})
export class CountryService {
    protected api = environment.back_api;

    constructor(private _httpClient: HttpClient) {}

    getContrys(): Observable<CountryEntitie[]> {
        return this._httpClient.get<CountryEntitie[]>(
            `${this.api}/country/get`,
        );
    }
}
