import { CityEffect } from './city.effect';
import { CountryEffect } from './country.effect';
import { DepartamentEffect } from './departament.effect';
import { ProfileEffect } from './profile.effect';
import { PublicEffect } from './public.effect';

export const appEffects: any[] = [
    CountryEffect,
    DepartamentEffect,
    CityEffect,
    PublicEffect,
    ProfileEffect,
];
