import { ActionReducerMap } from '@ngrx/store';
import { AppState } from '../states/app.state';
import { countryReducer } from './country.reducer';
import { departamentReducer } from './departament.reducer';
import { cityReducer } from './city.reducer';
import { profileReducer } from './profile.reducer.state';
import { publicReducer } from './public.reducer';

export const appRecuder: ActionReducerMap<AppState> = {
    country: countryReducer,
    departaments: departamentReducer,
    citys: cityReducer,
    profile: profileReducer,
    public: publicReducer,
};
