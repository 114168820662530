import { Injectable } from '@angular/core';
import { DepartamentRepository } from 'src/app/domain/repositorys/departament.repository';
import { DepartamentMapper } from '../mappers/departament.mapper';
import { DepartamentService } from 'src/app/services/departament.service';
import { Observable, map } from 'rxjs';
import { DepartamentModel } from 'src/app/domain/models/departament.model';

@Injectable({
    providedIn: 'root',
})
export class DepartamentIRepository extends DepartamentRepository {
    private _mapperDepartament = new DepartamentMapper();

    constructor(private _departamentService: DepartamentService) {
        super();
    }

    getAllDepartaments(id: number): Observable<DepartamentModel[]> {
        return this._departamentService
            .getAllDepartamentByCountry(id)
            .pipe(map(this._mapperDepartament.mapperArrayFrom));
    }
}
