import { createReducer, on } from '@ngrx/store';
import { initialContryState } from '../states/country.state';
import * as Actions from '../actions';

export const countryReducer = createReducer(
    initialContryState,
    on(Actions.getAllContrySuccess, (state, { contrys }) => {
        return {
            ...state,
            contrys: contrys,
        };
    }),
);
