<ng-container>
    <div class="flex justify-content-center flex-wrap">
        <font class="w-30rem">
            <p>{{ message }}</p>
        </font>
        <div class="w-full flex justify-content-end mt-5" *ngIf="url">
            <button
                pButton
                pRipple
                label="Cerrar"
                (click)="close()"
                class="p-3 p-button-warning p-button-outlined p-button-sm"
            ></button>
        </div>
    </div>
</ng-container>
