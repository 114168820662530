import { CommonModule } from '@angular/common';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { StoreModule } from '@ngrx/store';
import { appRecuder } from './storeRoot/reducers/app.reducer';
import { EffectsModule } from '@ngrx/effects';
import { appEffects } from './storeRoot/effects/app.effect';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { DialogMessageComponent } from './components/dialog-message/dialog-message.component';
import { TokenInterceptor } from './utilitis/interceptors/token.interceptor';
import { AppLayoutComponent } from './layout/app.layout.component';
import { ToastModule } from 'primeng/toast';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConfigService } from './services/config.service';

@NgModule({
    declarations: [AppComponent],
    imports: [
        CommonModule,
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        DynamicDialogModule,
        DialogMessageComponent,
        AppLayoutComponent,
        ToastModule,
        StoreModule.forRoot(appRecuder, {
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionImmutability: true,
            },
        }),
        EffectsModule.forRoot(appEffects),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: true,
        }),
    ],
    providers: [
        DialogService,
        ConfirmationService,
        MessageService,
        ConfigService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        }
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
