import { createReducer, on } from '@ngrx/store';
import { initialPublicState } from '../states/public.state';
import {
    getAllBrandsPublicSuccess,
    getAllCategorysPublicSuccess,
    getAllClientsGlobalSuccess,
    getAllPersonalShopperGlobalSuccess,
    getAllProductsGlobalSuccess,
    getAllStoreMallsPublicSuccess,
} from '../actions/public.action';

export const publicReducer = createReducer(
    initialPublicState,
    on(getAllCategorysPublicSuccess, (state, { categorys }) => {
        return {
            ...state,
            categorys: categorys,
        };
    }),
    on(getAllBrandsPublicSuccess, (state, { brands }) => {
        return {
            ...state,
            brands: brands,
        };
    }),
    on(getAllStoreMallsPublicSuccess, (state, { storemalls }) => {
        return {
            ...state,
            storeMalls: storemalls,
        };
    }),
    on(getAllProductsGlobalSuccess, (state, { products }) => {
        return {
            ...state,
            products: products,
        };
    }),
    on(getAllClientsGlobalSuccess, (state, { clients }) => {
        return {
            ...state,
            clients: clients,
        };
    }),
    on(getAllPersonalShopperGlobalSuccess, (state, { shoppers }) => {
        return {
            ...state,
            shoppers: shoppers,
        };
    }),
);
