import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ResponseData, ResponseMessage } from '../utilitis/interfaces/response';

@Injectable({
    providedIn: 'root',
})
export class ProfileService {
    protected api = environment.back_api;

    constructor(private _httpClient: HttpClient) {}

    updateToken(): Observable<ResponseData> {
        return this._httpClient.post<ResponseData>(`${this.api}/refresh`, {});
    }

    signOut(): Observable<ResponseMessage> {
        return this._httpClient.post<ResponseMessage>(`${this.api}/logout`, {});
    }
}
