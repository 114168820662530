import {
    HttpHandler,
    HttpHeaders,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';

import { Injectable } from '@angular/core';
import { StorageService } from 'src/app/services/storage.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(private _storageService: StorageService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        // Get the auth token from the service.
        const authToken = this._storageService.getToken();
        // Clone the request and replace the original headers with
        // cloned headers, updated with the authorization.
        let authReq = req;
        if (authToken) {
            const headers = new HttpHeaders({
                Authorization: `Bearer ${authToken}`,
            });
            authReq = req.clone({ headers });
        }

        // send cloned request with header to the next handler.
        return next.handle(authReq);
    }
}
