import {
    ChangeDetectorRef,
    Component,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import {
    animate,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { LayoutService } from '../../services/app.layout.service';
import { MenuService } from '../../services/app.menu.service';
import { CommonModule } from '@angular/common';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/storeRoot/states/app.state';
import { ProfileModel } from 'src/app/domain/models/profile.model';
import { getProfile$ } from 'src/app/storeRoot/selectors/profile.select';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: '[app-menuitem]',
    standalone: true,
    imports: [CommonModule, RouterModule, FontAwesomeModule],
    template: `
        <ng-container>
            <div
                *ngIf="root && item.visible !== false && item.allow.includes(profile.rolCode)"
                class="layout-menuitem-root-text"
            >
                {{ item.label }}
            </div>
            <a
                *ngIf="
                    (!item.routerLink || item.items) && item.visible !== false && item.allow.includes(profile.rolCode)
                "
                [attr.href]="item.url"
                (click)="itemClick($event)"
                [ngClass]="item.class"
                [attr.target]="item.target"
                tabindex="0"
                pRipple
            >
            <fa-icon size="lg" class="layout-menuitem-icon pi-fw" [icon]="item.icon" *ngIf="item.icon"></fa-icon>
                <span class="layout-menuitem-text">{{ item.label }}</span>
                <i
                    class="pi pi-fw pi-angle-down layout-submenu-toggler"
                    *ngIf="item.items"
                ></i>
            </a>
            <a
                *ngIf="item.routerLink && !item.items && item.visible !== false &&  item.allow.includes(profile.rolCode)"
                (click)="itemClick($event)"
                [ngClass]="{
                    'active-route': router.url.includes(item.base)
                }"
                [routerLink]="item.routerLink"
                [routerLinkActive]="addClass(item.base, item.routerLink)"
                [routerLinkActiveOptions]="
                    item.routerLinkActiveOptions || {
                        paths: 'exact',
                        queryParams: 'ignored',
                        matrixParams: 'ignored',
                        fragment: 'ignored'
                    }
                "
                [fragment]="item.fragment"
                [queryParamsHandling]="item.queryParamsHandling"
                [preserveFragment]="item.preserveFragment"
                [skipLocationChange]="item.skipLocationChange"
                [replaceUrl]="item.replaceUrl"
                [state]="item.state"
                [queryParams]="item.queryParams"
                [attr.target]="item.target"
                tabindex="0"
                pRipple
            >
            <fa-icon size="lg" ngClass="layout-menuitem-icon pi-fw" [icon]="item.icon"></fa-icon>
                <span class="layout-menuitem-text">{{ item.label }}</span>
                <i
                    class="pi pi-fw pi-angle-down layout-submenu-toggler"
                    *ngIf="item.items"
                ></i>
            </a>

            <ul
                *ngIf="item.items && item.visible !== false"
                [@children]="submenuAnimation"
            >
                <ng-template
                    ngFor
                    let-child
                    let-i="index"
                    [ngForOf]="item.items"
                >
                    <li
                        app-menuitem
                        [item]="child"
                        [index]="i"
                        [parentKey]="key"
                        [class]="child.badgeClass"
                    ></li>
                </ng-template>
            </ul>
        </ng-container>
    `,
    animations: [
        trigger('children', [
            state(
                'collapsed',
                style({
                    height: '0',
                }),
            ),
            state(
                'expanded',
                style({
                    height: '*',
                }),
            ),
            transition(
                'collapsed <=> expanded',
                animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'),
            ),
        ]),
    ],
})
export class AppMenuitemComponent implements OnInit, OnDestroy {
    @Input() item: any;

    @Input() index!: number;

    @Input() @HostBinding('class.layout-root-menuitem') root!: boolean;

    @Input() parentKey!: string;

    active = false;

    menuSourceSubscription: Subscription;

    menuResetSubscription: Subscription;

    key: string = '';
    profile: ProfileModel;

    constructor(
        public layoutService: LayoutService,
        private cd: ChangeDetectorRef,
        public router: Router,
        private menuService: MenuService,
        protected store$: Store<AppState>
    ) {
        this.store$.pipe(select(getProfile$)).subscribe((resp: ProfileModel)=>{
            this.profile = resp;
        })
        this.menuSourceSubscription = this.menuService.menuSource$.subscribe(
            (value) => {
                Promise.resolve(null).then(() => {
                    if (value.routeEvent) {
                        this.active =
                            value.key === this.key ||
                            value.key.startsWith(this.key + '-')
                                ? true
                                : false;
                    } else {
                        if (
                            value.key !== this.key &&
                            !value.key.startsWith(this.key + '-')
                        ) {
                            this.active = false;
                        }
                    }
                });
            },
        );

        this.menuResetSubscription = this.menuService.resetSource$.subscribe(
            () => {
                this.active = false;
            },
        );

        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe((params) => {
                if (this.item.routerLink) {
                    this.updateActiveStateFromRoute();
                }
            });
    }

    ngOnInit() {
        this.key = this.parentKey
            ? this.parentKey + '-' + this.index
            : String(this.index);

        if (this.item.routerLink) {
            this.updateActiveStateFromRoute();
        }
    }

    updateActiveStateFromRoute() {
        let activeRoute = this.router.isActive(this.item.routerLink[0], {
            paths: 'exact',
            queryParams: 'ignored',
            matrixParams: 'ignored',
            fragment: 'ignored',
        });

        if (activeRoute) {
            this.menuService.onMenuStateChange({
                key: this.key,
                routeEvent: true,
            });
        }
    }

    itemClick(event: Event) {
        // avoid processing disabled items
        if (this.item.disabled) {
            event.preventDefault();
            return;
        }

        // execute command
        if (this.item.command) {
            this.item.command({ originalEvent: event, item: this.item });
        }

        // toggle active state
        if (this.item.items) {
            this.active = !this.active;
        }

        this.menuService.onMenuStateChange({ key: this.key });
    }

    get submenuAnimation() {
        return this.root ? 'expanded' : this.active ? 'expanded' : 'collapsed';
    }

    addClass(base: string, link: string[]){
        return link.find( l => l.includes(base)) || this.router.url.includes(base) ? 'active-route' : ''
    }

    @HostBinding('class.active-menuitem')
    get activeClass() {
        return this.active && !this.root;
    }

    ngOnDestroy() {
        if (this.menuSourceSubscription) {
            this.menuSourceSubscription.unsubscribe();
        }

        if (this.menuResetSubscription) {
            this.menuResetSubscription.unsubscribe();
        }
    }
}
