import { createReducer, on } from '@ngrx/store';
import { initialDepartamentState } from '../states/departament.state';
import * as Actions from '../actions';

export const departamentReducer = createReducer(
    initialDepartamentState,
    on(Actions.getDepartamentByCountrySuccess, (state, { departaments }) => {
        return {
            ...state,
            departaments: departaments,
        };
    }),
);
