import { createReducer, on } from '@ngrx/store';
import { initialProfileState } from '../states/profile.state';
import * as Actions from '../actions';

export const profileReducer = createReducer(
    initialProfileState,
    on(Actions.setProfile, (state, { profile }) => {
        return {
            ...state,
            profile: profile,
        };
    }),
);
