import { BrandPublicModel } from 'src/app/domain/models/brand-public.model';
import { CategoryPublicModel } from 'src/app/domain/models/category-public.model';
import { ClientPublicModel } from 'src/app/domain/models/client.model';
import { PersonalShopperPublicModel } from 'src/app/domain/models/personalShopper.model';
import { ProductGlobalModel } from 'src/app/domain/models/product-global.model';
import { StorePublicModel } from 'src/app/domain/models/store-public.model';

export interface PublicState {
    categorys: CategoryPublicModel[];
    brands: BrandPublicModel[];
    storeMalls: StorePublicModel[];
    products: ProductGlobalModel[],
    shoppers: PersonalShopperPublicModel[],
    clients: ClientPublicModel[],
}

export const initialPublicState: PublicState = {
    categorys: [],
    brands: [],
    storeMalls: [],
    products: [],
    shoppers: [],
    clients: []
};
