import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CityEntitie } from '../adapters/entities/city.entitie';

@Injectable({
    providedIn: 'root',
})
export class CityService {
    protected api$ = environment.back_api;

    constructor(private _httpClient: HttpClient) {}

    getAllCityByDepartament(id: number): Observable<CityEntitie[]> {
        return this._httpClient.get<CityEntitie[]>(
            `${this.api$}/city/get_city_state?state_id=${id}`,
        );
    }
}
