import { CountryEntitie } from './../entities/country.entitie';
import { Mapper } from 'src/app/utilitis/base/mapper';
import { StorePublicEntitie } from '../entities/store-public.entitie';
import { StorePublicModel } from 'src/app/domain/models/store-public.model';
import { CountryModel } from 'src/app/domain/models/country.model';


export class StoreMallPublicMapper extends Mapper<
    StorePublicEntitie | StorePublicEntitie[],
    StorePublicModel | StorePublicModel[]
> {
    mapperArrayFrom(payload: StorePublicEntitie[]): StorePublicModel[] {
        let data: StorePublicModel[] = [];
        payload.forEach((value: StorePublicEntitie) => {
            const dataConvert = this.mapperFrom(value);
            data.push(dataConvert);
        });
        return data;
    }

    mapperTo(payload: StorePublicModel): StorePublicEntitie {
        return {
            _uuid: payload._uuid,
            name: payload.name,
            address: payload.address,
            suburb: payload.subUrb,
            postal_code: payload.postalCode,
            num_phone: payload.numPhone,
            country: payload.country,
            state: payload.state,
            city: payload.city
        };
    }

    mapperFrom(payload: StorePublicEntitie): StorePublicModel {
        return {
            _uuid: payload._uuid,
            name: payload.name,
            address: payload.address,
            subUrb: payload.suburb,
            postalCode: payload.postal_code,
            numPhone: payload.num_phone,
            country: payload.country,
            state: payload.state,
            city: payload.city
        };
    }
}
