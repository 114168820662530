import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { BrandPublicModel } from 'src/app/domain/models/brand-public.model';
import { BrandPublicRepository } from 'src/app/domain/repositorys/brand-public.repository';
import { BrandPublicMapper } from '../mappers/brand-public.mapper';
import { PublicService } from 'src/app/services/public.service';
import { BrandPublicEntitie } from '../entities/brand-public.entitie';

@Injectable({
    providedIn: 'root',
})
export class BrandPublicIRepository extends BrandPublicRepository {
    protected _mapperBrand$ = new BrandPublicMapper();

    constructor(private _servicePublic: PublicService) {
        super();
    }

    getAll(store?: string): Observable<BrandPublicModel[]> {
        return this._servicePublic
            .getAllBrands(store)
            .pipe(
                map((e: BrandPublicEntitie[]) =>
                    this._mapperBrand$.mapperArrayFrom(e),
                ),
            );
    }
}
