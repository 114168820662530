import { Mapper } from 'src/app/utilitis/base/mapper';
import { CategoryPublicEntitie } from '../entities/category-public.entitie';
import { CategoryPublicModel } from 'src/app/domain/models/category-public.model';

export class CategoryPublicMapper extends Mapper<
    CategoryPublicEntitie | CategoryPublicEntitie[],
    CategoryPublicModel | CategoryPublicModel[]
> {
    mapperArrayFrom(payload: CategoryPublicEntitie[]): CategoryPublicModel[] {
        let data: CategoryPublicModel[] = [];
        payload.forEach((value: CategoryPublicEntitie) => {
            const dataConvert = this.mapperFrom(value);
            data.push(dataConvert);
        });
        return data;
    }

    mapperFrom(payload: CategoryPublicEntitie): CategoryPublicModel {
        return {
            _uuid: payload._uuid,
            name: payload.name,
            image: payload.image,
        };
    }

    mapperTo(payload: CategoryPublicModel): CategoryPublicEntitie {
        return {
            _uuid: payload._uuid,
            name: payload.name,
            image: payload.image,
        };
    }
}
