import { PersonalShopperPublicModel } from './../../domain/models/personalShopper.model';
import { Mapper } from 'src/app/utilitis/base/mapper';
import { PersonalShopperPublicEntitie } from '../entities/personalShopper.entitie';


export class PersonalShopperPublicMapper extends Mapper<
    PersonalShopperPublicEntitie | PersonalShopperPublicEntitie[],
    PersonalShopperPublicModel | PersonalShopperPublicModel[]
> {
    mapperArrayFrom(payload: PersonalShopperPublicEntitie[]): PersonalShopperPublicModel[] {
        let data: PersonalShopperPublicModel[] = [];
        payload.forEach((value: PersonalShopperPublicEntitie) => {
            const dataConvert = this.mapperFrom(value);
            data.push(dataConvert);
        });
        return data;
    }

    mapperTo(payload: PersonalShopperPublicModel): PersonalShopperPublicEntitie {
        return {
            _uuid: payload._uuid,
            full_name: payload.fullName,
            email: payload.email,
        };
    }

    mapperFrom(payload: PersonalShopperPublicEntitie): PersonalShopperPublicModel {
        return {
            _uuid: payload._uuid,
            fullName: payload.full_name,
            email: payload.email
        };
    }
}
