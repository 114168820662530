import { createReducer, on } from '@ngrx/store';
import { initialCityState } from '../states/city.state';
import * as ActionsP from '../actions';

export const cityReducer = createReducer(
    initialCityState,
    on(ActionsP.getAllCitysByDepartamentSuccess, (state, { citys }) => {
        return {
            ...state,
            citys: citys,
        };
    }),
);
