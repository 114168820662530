import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BrandPublicIRepository } from 'src/app/adapters/repositorys/brand-public.irepository';
import { CategoryPublicIRepository } from 'src/app/adapters/repositorys/category-public.irepository';

import {
    getAllBrandsPublic,
    getAllBrandsPublicSuccess,
    getAllCategorysPublic,
    getAllCategorysPublicSuccess,
    getAllClientsGlobal,
    getAllClientsGlobalSuccess,
    getAllPersonalShopperGlobal,
    getAllPersonalShopperGlobalSuccess,
    getAllProductsGlobal,
    getAllProductsGlobalSuccess,
    getAllStoreMallsPublic,
    getAllStoreMallsPublicSuccess,
} from '../actions/public.action';
import { catchError, exhaustMap, finalize, map, of } from 'rxjs';
import { StorePublicIRepository } from 'src/app/adapters/repositorys/store-public.irepository';
import { ProductGlobalIRepository } from 'src/app/adapters/repositorys/product-global.irepository';
import { PersonalShopperPublicIRepository } from 'src/app/adapters/repositorys/personal-shopper-public.irepository';
import { ClientPublicIRepository } from 'src/app/adapters/repositorys/client-public.irepository';

@Injectable()
export class PublicEffect {
    constructor(
        protected actions$: Actions,
        private _categoryService: CategoryPublicIRepository,
        private _brandService: BrandPublicIRepository,
        private _storeMallService: StorePublicIRepository,
        private _productervice: ProductGlobalIRepository,
        private _shopperService: PersonalShopperPublicIRepository,
        private _clientService: ClientPublicIRepository,

    ) {}

    getAllCategorys$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getAllCategorysPublic),
            exhaustMap((action) => {
                return this._categoryService.getAll().pipe(
                    map((resp) => {
                        return getAllCategorysPublicSuccess({
                            categorys: resp,
                        });
                    }),
                    catchError((error) => {
                        console.log(error);

                        return of();
                    }),
                    finalize(() => {}),
                );
            }),
        ),
    );

    getAllBrands$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getAllBrandsPublic),
            exhaustMap((action) => {
                return this._brandService.getAll(action.store).pipe(
                    map((resp) => {
                        return getAllBrandsPublicSuccess({
                            brands: resp,
                        });
                    }),
                    catchError((error) => {
                        console.log(error);

                        return of();
                    }),
                    finalize(() => {}),
                );
            }),
        ),
    );

    getAllStoreMalls$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getAllStoreMallsPublic),
            exhaustMap((action) => {
                return this._storeMallService.getAll().pipe(
                    map((resp) => {
                        return getAllStoreMallsPublicSuccess({
                            storemalls: resp,
                        });
                    }),
                    catchError((error) => {
                        console.log(error);

                        return of();
                    }),
                    finalize(() => {}),
                );
            }),
        ),
    );

    getAllProducts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getAllProductsGlobal),
            exhaustMap((action) => {
                return this._productervice.getAll(action.id).pipe(
                    map((resp) => {
                        return getAllProductsGlobalSuccess({
                            products: resp,
                        });
                    }),
                    catchError((error) => {
                        console.log(error);
                        return of();
                    }),
                    finalize(() => {}),
                );
            }),
        ),
    );

    getAllShoppers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getAllPersonalShopperGlobal),
            exhaustMap((action) => {
                return this._shopperService.getAll().pipe(
                    map((resp) => {
                        return getAllPersonalShopperGlobalSuccess({
                            shoppers: resp,
                        });
                    }),
                    catchError((error) => {
                        console.log(error);
                        return of();
                    }),
                    finalize(() => {}),
                );
            }),
        ),
    );

    getAllClients$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getAllClientsGlobal),
            exhaustMap((action) => {
                return this._clientService.getAll().pipe(
                    map((resp) => {
                        return getAllClientsGlobalSuccess({
                            clients: resp,
                        });
                    }),
                    catchError((error) => {
                        console.log(error);
                        return of();
                    }),
                    finalize(() => {}),
                );
            }),
        ),
    );
}
