import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { StorageService } from './services/storage.service';

import loginChange from './utilitis/loads/login';
import { ConfigService } from './services/config.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
    isLogged: boolean = false;

    constructor(
        private primengConfig: PrimeNGConfig,
        private _storageService: StorageService,
        private _configService: ConfigService
    ) {
        loginChange.subscribe((resp: boolean) => {
            this.isLogged = resp;
        });
        this.observerLogin();
    }
    ngOnInit(): void {
        this.primengConfig.ripple = true;
        this.primengConfig.setTranslation({
            accept: 'Aceptar',
            reject: 'Cancelar',
            //translations
            upload: 'Cargar',
            choose: 'Seleccionar',
            cancel: 'Cancelar',
            clear: 'Limpiar',
            pending: 'Pendiente',
            dayNames: [
                'Domingo',
                'Lunes',
                'Martes',
                'Miercoles',
                'Jueves',
                'Viernes',
                'Sabado',
            ],
            dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vier', 'Sab'],
            dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
            monthNames: [
                'Enero',
                'Febrero',
                'Marzo',
                'Abril',
                'Mayo',
                'Junio',
                'Julio',
                'Agosto',
                'Septiembre',
                'Octubre',
                'Noviembre',
                'Diciembre',
            ],
            monthNamesShort: [
                'Ene',
                'Feb',
                'Mar',
                'Abr',
                'May',
                'Jun',
                'Jul',
                'Ago',
                'Sep',
                'Oct',
                'Nov',
                'Dic',
            ],
            dateFormat: 'mm/dd/yy',
            firstDayOfWeek: 0,
            today: 'Hoy',
            weekHeader: 'Sem',
            weak: 'Débil',
            emptyMessage: 'No se han encontrado resultados',
            passwordPrompt: 'introducir la contraseña',
            medium: 'medio',
            strong: 'fuerte',
        });
        const browserInfo = navigator.language;
        const getLanguage = browserInfo.split("-") ?? ['es'];
        this._configService.startConfig({
            language: getLanguage[0]
        }).subscribe(()=>{})
    }

    observerLogin() {
        if (this._storageService.isLoggedIn()) {
            this.isLogged = this._storageService.isLoggedIn();
            this._storageService.setProfiletState();
            this._storageService.verifyToken();
        }
    }
}
