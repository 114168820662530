import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DepartamentEntitie } from '../adapters/entities/departament.entitie';

@Injectable({
    providedIn: 'root',
})
export class DepartamentService {
    protected api$ = environment.back_api;

    constructor(private _httpClient: HttpClient) {}

    getAllDepartamentByCountry(id: number): Observable<DepartamentEntitie[]> {
        return this._httpClient.get<DepartamentEntitie[]>(
            `${this.api$}/state/get_state_country?country_id=${id}`,
        );
    }
}
