import { Injectable } from '@angular/core';
import { PublicService } from 'src/app/services/public.service';
import { Observable, map } from 'rxjs';
import { ProductGlobalRepository } from 'src/app/domain/repositorys/product-global.repository';
import { ProductGlobalMapper } from '../mappers/product-global.mapper';
import { ProductGlobalModel } from 'src/app/domain/models/product-global.model';
import { ProductGlobalEntitie } from '../entities/product-global.entitie';


@Injectable({ providedIn: 'root' })
export class ProductGlobalIRepository extends ProductGlobalRepository {
    private _mapperProduct = new ProductGlobalMapper();

    constructor(private _servicePublic: PublicService) {
        super();
    }

    getAll(id?: string): Observable<ProductGlobalModel[]> {
        return this._servicePublic
            .getAllProducts(id)
            .pipe(
                map((e: ProductGlobalEntitie[]) =>
                    this._mapperProduct.mapperArrayFrom(e),
                ),
            );
    }
}
