import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CategoryPublicEntitie } from '../adapters/entities/category-public.entitie';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BrandPublicEntitie } from '../adapters/entities/brand-public.entitie';
import { StorePublicEntitie } from '../adapters/entities/store-public.entitie';
import { ClientPublicEntitie } from '../adapters/entities/client.entitie';
import { ProductGlobalEntitie } from '../adapters/entities/product-global.entitie';
import { PersonalShopperPublicEntitie } from '../adapters/entities/personalShopper.entitie';

@Injectable({
    providedIn: 'root',
})
export class PublicService {
    private _api = environment.back_api;

    constructor(private _httpClient: HttpClient) {}

    getAllStoreMalls(): Observable<StorePublicEntitie[]> {
        return this._httpClient
            .get<StorePublicEntitie[]>(`${this._api}/store/get/list`)
            .pipe(map((e: any) => e.data));
    }

    getAllBrands(store?: string): Observable<BrandPublicEntitie[]> {
        let params: HttpParams = new HttpParams();
        params = params.set('store', store);
        return this._httpClient
            .get<BrandPublicEntitie[]>(`${this._api}/brand/get/list`,{params:params})
            .pipe(map((e: any) => e.data));
    }

    getAllCategorys(): Observable<CategoryPublicEntitie[]> {
        return this._httpClient
            .get<CategoryPublicEntitie[]>(`${this._api}/category/get/list`)
            .pipe(map((e: any) => e.data));
    }

    getAllClients(): Observable<ClientPublicEntitie[]> {
        return this._httpClient
            .get<ClientPublicEntitie[]>(`${this._api}/client/global`)
            .pipe(map((e: any) => e.data));
    }

    getAllShoppers(): Observable<PersonalShopperPublicEntitie[]> {
        return this._httpClient
            .get<PersonalShopperPublicEntitie[]>(`${this._api}/personal_shopper/global`)
            .pipe(map((e: any) => e.data));
    }

    getAllProducts(id?: string): Observable<ProductGlobalEntitie[]> {
        return this._httpClient
            .get<ProductGlobalEntitie[]>(`${this._api}/product/global/${id}`)
            .pipe(map((e: any) => e.data));
    }
}
