import { CommonModule } from '@angular/common';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { LayoutService } from '../../services/app.layout.service';
import { TooltipModule } from 'primeng/tooltip';

import { AppState } from 'src/app/storeRoot/states/app.state';
import { Store } from '@ngrx/store';
import { logOut } from 'src/app/storeRoot/actions';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    standalone: true,
    imports: [CommonModule, RouterModule, TooltipModule],
})
export class AppTopBarComponent {
    items!: MenuItem[];

    @ViewChild('menubutton') menuButton!: ElementRef;

    @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

    @ViewChild('topbarmenu') menu!: ElementRef;

    constructor(
        public layoutService: LayoutService,
        protected store$: Store<AppState>,
    ) {}

    logOut() {
        this.store$.dispatch(logOut());
    }
}
