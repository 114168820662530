import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DepartamentModel } from 'src/app/domain/models/departament.model';
import * as ActionsP from '../actions';
import { catchError, exhaustMap, finalize, map, of } from 'rxjs';
import { DepartamentIRepository } from 'src/app/adapters/repositorys/departament.IRepository';

@Injectable()
export class DepartamentEffect {
    constructor(
        private actions$: Actions,
        private _departamentService: DepartamentIRepository,
    ) {}

    getAllDepartaments$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActionsP.getDepartamentByCountry),
            exhaustMap((actions) => {
                return this._departamentService
                    .getAllDepartaments(actions.id)
                    .pipe(
                        map((resp: DepartamentModel[]) => {
                            return ActionsP.getDepartamentByCountrySuccess({
                                departaments: resp,
                            });
                        }),
                        catchError(() => {
                            return of();
                        }),
                        finalize(() => {}),
                    );
            }),
        ),
    );
}
