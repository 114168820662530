import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ProfileIRepository } from 'src/app/adapters/repositorys/profile.IRepository';
import {
    logOut,
    logOutSuccess,
    refreshToken,
    refreshTokenSuccess,
} from '../actions';
import { catchError, exhaustMap, finalize, map, of } from 'rxjs';
import {
    ResponseData,
    ResponseMessage,
} from 'src/app/utilitis/interfaces/response';
import { StorageService } from 'src/app/services/storage.service';
import loginChange from 'src/app/utilitis/loads/login';
import { Router } from '@angular/router';

@Injectable()
export class ProfileEffect {
    flagError: boolean = false;
    constructor(
        protected actions$: Actions,
        private _profileService: ProfileIRepository,
        private _storageService: StorageService,
        private _router: Router,
    ) {}

    updateToken$ = createEffect(() =>
        this.actions$.pipe(
            ofType(refreshToken),
            exhaustMap((action) => {
                return this._profileService.updateToken().pipe(
                    map((resp: ResponseData) => {
                        this._storageService.setToken(
                            resp.data.authorization.token,
                        );
                        this._storageService.verifyToken();
                        return refreshTokenSuccess();
                    }),
                    catchError(() => {
                        return of();
                    }),
                    finalize(() => {}),
                );
            }),
        ),
    );

    logOut$ = createEffect(() =>
        this.actions$.pipe(
            ofType(logOut),
            exhaustMap((action) => {
                this.flagError = false;
                return this._profileService.logOut().pipe(
                    map((resp: ResponseMessage) => {
                        this._storageService.destroyVerify();
                        this._storageService.removeAll();
                        return logOutSuccess();
                    }),
                    catchError(() => {
                        this.flagError = true;
                        return of();
                    }),
                    finalize(() => {
                        if (!this.flagError){
                            this._router.navigate(['/sign-in']).then((resp: boolean)=>{
                                if (resp){
                                    loginChange.next(false);
                                }
                            })
                        }
                    }),
                );
            }),
        ),
    );
}
