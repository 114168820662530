import { Injectable } from '@angular/core';
import { CategoryPublicRepository } from 'src/app/domain/repositorys/category-public.repository';
import { PublicService } from 'src/app/services/public.service';
import { CategoryPublicMapper } from '../mappers/category-public.mapper';
import { Observable, map } from 'rxjs';
import { CategoryPublicModel } from 'src/app/domain/models/category-public.model';
import { CategoryPublicEntitie } from '../entities/category-public.entitie';

@Injectable({
    providedIn: 'root',
})
export class CategoryPublicIRepository extends CategoryPublicRepository {
    protected _mapperCategory$ = new CategoryPublicMapper();

    constructor(private _servicePublic: PublicService) {
        super();
    }

    getAll(): Observable<CategoryPublicModel[]> {
        return this._servicePublic
            .getAllCategorys()
            .pipe(
                map((e: CategoryPublicEntitie[]) =>
                    this._mapperCategory$.mapperArrayFrom(e),
                ),
            );
    }
}
