import { Injectable } from '@angular/core';
import { PublicService } from 'src/app/services/public.service';
import { Observable, map } from 'rxjs';
import { ClientPublicRepository } from 'src/app/domain/repositorys/client-public.repository';
import { ClientPublicModel } from 'src/app/domain/models/client.model';
import { ClientPublicEntitie } from '../entities/client.entitie';
import { ClientPublicMapper } from '../mappers/client-public.mapper';


@Injectable({ providedIn: 'root' })
export class ClientPublicIRepository extends ClientPublicRepository {
    private _mapperClientPublic= new ClientPublicMapper();

    constructor(private _servicePublic: PublicService) {
        super();
    }

    getAll(): Observable<ClientPublicModel[]> {
        return this._servicePublic
            .getAllClients()
            .pipe(
                map((e: ClientPublicEntitie[]) =>
                    this._mapperClientPublic.mapperArrayFrom(e),
                ),
            );
    }
}
