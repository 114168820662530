import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { checkLogInGuard } from './utilitis/guards/check-log-in.guard';
import { authGuard } from './utilitis/guards/auth.guard';

@NgModule({
    imports: [
        RouterModule.forRoot(
            [
                {
                    path: '',
                    redirectTo: '/sign-in',
                    pathMatch: 'full',
                },
                {
                    path: 'activation',
                    loadChildren: () =>
                        import('./moduls').then(
                            (m) => m.ActivactionPublicModule
                        ),
                },
                {
                    path: 'sign-in',
                    loadChildren: () =>
                        import('./moduls').then((m) => m.SignInModule),
                    canActivate: [checkLogInGuard],
                },
                {
                    path: 'sign-up',
                    loadChildren: () =>
                        import('./moduls').then((m) => m.SignUpModule),
                    canActivate: [checkLogInGuard],
                },
                {
                    path: 'dashboard',
                    loadChildren: () =>
                        import('./moduls').then((m) => m.DashboardModule),
                    canActivate: [authGuard],
                },
                {
                    path: 'user',
                    loadChildren: () =>
                        import('./moduls').then((m) => m.UserModule),
                    canActivate: [authGuard],
                },
                {
                    path: 'inventory',
                    loadChildren: () =>
                        import('./moduls').then((m) => m.InventoryModule),
                    canActivate: [authGuard],
                },
                {
                    path: 'config',
                    canActivate: [authGuard],
                    loadChildren: () =>
                        import('./moduls').then((m) => m.ConfigModule),
                },
                {
                    path: 'logistic',
                    canActivate: [authGuard],
                    loadChildren: () =>
                        import('./moduls').then((m) => m.LogisticsModule),
                },
            ],
            {
                scrollPositionRestoration: 'enabled',
                anchorScrolling: 'enabled',
                onSameUrlNavigation: 'reload',
            }
        ),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
