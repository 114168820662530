import { Injectable } from '@angular/core';
import { PublicService } from 'src/app/services/public.service';
import { StoreMallPublicMapper } from '../mappers/store-public.mapper';
import { StorePublicRepository } from 'src/app/domain/repositorys/store-public.repository';
import { Observable, map } from 'rxjs';
import { StorePublicModel } from 'src/app/domain/models/store-public.model';
import { StorePublicEntitie } from '../entities/store-public.entitie';


@Injectable({ providedIn: 'root' })
export class StorePublicIRepository extends StorePublicRepository {
    private _mapperStoreMall = new StoreMallPublicMapper();

    constructor(private _servicePublic: PublicService) {
        super();
    }

    getAll(): Observable<StorePublicModel[]> {
        return this._servicePublic
            .getAllStoreMalls()
            .pipe(
                map((e: StorePublicEntitie[]) =>
                    this._mapperStoreMall.mapperArrayFrom(e),
                ),
            );
    }
}
