import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import {
    DialogService,
    DynamicDialogComponent,
    DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { RippleModule } from 'primeng/ripple';

@Component({
    selector: 'app-dialog-message',
    standalone: true,
    imports: [ButtonModule, RippleModule],
    templateUrl: './dialog-message.component.html',
    styleUrl: './dialog-message.component.scss',
})
export class DialogMessageComponent implements OnInit, OnDestroy {
    message: string = '';
    instance!: DynamicDialogComponent;
    url: string = '';

    constructor(
        private _ref: DynamicDialogRef,
        private _dialogService: DialogService,
        private _router: Router,
    ) {
        this.instance = this._dialogService.getInstance(this._ref);
    }

    ngOnInit(): void {
        if (this.instance && this.instance.data) {
            this.message = this.instance.data['resp']['message'];
        }

        if (this.instance && this.instance.data['url']) {
            this.url = this.instance.data['url'];
        }
    }

    close(): void {
        this._ref.close();
        this._router.navigate([this.url]);
    }

    ngOnDestroy(): void {
        this._ref.close();
    }
}
