import { Mapper } from 'src/app/utilitis/base/mapper';
import { BrandPublicEntitie } from '../entities/brand-public.entitie';
import { BrandPublicModel } from 'src/app/domain/models/brand-public.model';

export class BrandPublicMapper extends Mapper<
    BrandPublicEntitie | BrandPublicEntitie[],
    BrandPublicModel | BrandPublicModel[]
> {
    mapperFrom(payload: BrandPublicEntitie): BrandPublicModel {
        return {
            _uuid: payload._uuid,
            name: payload.name,
        };
    }

    mapperTo(payload: BrandPublicModel): BrandPublicEntitie {
        return {
            _uuid: payload._uuid,
            name: payload.name,
        };
    }

    mapperArrayFrom(payload: BrandPublicEntitie[]): BrandPublicModel[] {
        let data: BrandPublicModel[] = [];
        payload.forEach((value: BrandPublicEntitie) => {
            const dataConvert = this.mapperFrom(value);
            data.push(dataConvert);
        });

        return data;
    }
}
