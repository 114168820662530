import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { CountryModel } from 'src/app/domain/models/country.model';
import { CountryRepository } from 'src/app/domain/repositorys/country.repository';
import { CountryService } from 'src/app/services/country.service';
import { CountryMapper } from '../mappers/country.mapper';

@Injectable({
    providedIn: 'root',
})
export class CountryIRepository extends CountryRepository {
    private _mapperCountry = new CountryMapper();

    constructor(private _countryService: CountryService) {
        super();
    }

    getAllContrys(): Observable<CountryModel[]> {
        return this._countryService
            .getContrys()
            .pipe(map(this._mapperCountry.mapperArrayFrom));
    }
}
