import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ProfileRepository } from 'src/app/domain/repositorys/profile.repository';
import { ProfileService } from 'src/app/services/profile.service';

import {
    ResponseData,
    ResponseMessage,
} from 'src/app/utilitis/interfaces/response';

@Injectable({
    providedIn: 'root',
})
export class ProfileIRepository extends ProfileRepository {
    constructor(private _profileService: ProfileService) {
        super();
    }

    updateToken(): Observable<ResponseData> {
        return this._profileService.updateToken().pipe(map((e) => e));
    }

    logOut(): Observable<ResponseMessage> {
        return this._profileService.signOut().pipe(map((e) => e));
    }
}
